<template>
  <div>
    <!-- <b-breadcrumb
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb> -->
    <h3 class="mt-5">
      {{ $getVisibleNames("mesh.period", true, "Períodos") }}
      <AlternativeNameButton
        :table_name="'mesh.period'"
        :allows_crud="allows_crud"
      ></AlternativeNameButton>
    </h3>
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <ButtonMedium
          v-can="'mesh.add_period'"
          v-if="allows_crud"
          :tooltip_text="`Agregar ${$getVisibleNames(
            'mesh.period',
            false,
            'Período'
          )}`"
          :text_button="`Agregar`"
          :click_button="() => $bvModal.show(`new-period-modal`)"
          :icon="'plus'"
          :variant="'primary'"
        >
        </ButtonMedium>
        <!-- <button
          v-can="'mesh.add_period'"
          v-if="allows_crud"
          class="btn btn-secondary btn-sm"
          @click="$bvModal.show(`new-period-modal`)"
        >
          Agregar {{ $getVisibleNames("mesh.period", false, "Período") }}
        </button> -->
        <!-- Período -->
      </div>
    </div>
    <GenericBTable
      v-if="periods.length > 0"
      :filterCustom="filterCustom"
      @emitChangeSearchField="changeInputSearch"
      :items="periods"
      :pagination="periods.length"
      :fields="periodsFields"
      :filter="input_search"
      :show_pagination="true"
      :search_filter="true"
      :columns_display="true"
      :allows_crud="allows_crud"
      :display_id="display_id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template> </template>
      <template v-if="allows_crud" #cell(actions)="row">
        <ButtonSmall
          v-can="'mesh.change_period'"
          :tooltip_text="`Editar ${$getVisibleNames(
            'mesh.period',
            false,
            'Período'
          )}`"
          :click_button="
            () => $bvModal.show(`edit-period-modal-${row.item.id}`)
          "
          :class="``"
          :icon="'square'"
        ></ButtonSmall>
        <!-- <button-edit
          v-can="'mesh.change_period'"
          @click="$bvModal.show(`edit-period-modal-${row.item.id}`)"
          v-b-tooltip.v-secondary.noninteractive="
            `Editar ${$getVisibleNames('mesh.period', false, 'Período')}`
          "
        ></button-edit> -->
        <!-- <button-delete
            @click="askForDeletePeriod(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="`Eliminar ${$getVisibleNames('mesh.period', false,'Período')}`"
          ></button-delete> -->
        <b-modal
          :id="`edit-period-modal-${row.item.id}`"
          :title="`Editar ${$getVisibleNames('mesh.period', false, 'Período')}`"
          size="md"
          hide-footer
        >
          <PeriodForm
            :Period="row.item"
            @updated="slotUpdatedPeriod"
            :institution_id="row.item.school"
            :show_title="false"
          ></PeriodForm>
        </b-modal>
      </template>
    </GenericBTable>
    <div v-else>
      <br />
      <strong
        >No se a encontrado ningún
        {{ $getVisibleNames("mesh.period", false, "Período") }}.</strong
      >
    </div>
    <b-modal
      :id="`new-period-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames('mesh.period', false, 'Período')}`"
      size="lg"
    >
      <PeriodForm @created="slotCreatedPeriod"></PeriodForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "PeriodView",
  components: {
    PeriodForm: () =>
      import("@/components/mesh/CurricularResources/Period/PeriodForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    display_id: {},
  },
  data() {
    return {
      input_search: "",
      // breadcrumb_items: [
      //   { text: "Definiciones Organizacionales", active: true },
      //   { text: "Períodos", active: true },
      // ],
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      periods: names.PERIODS,
      regimes: names.REGIMES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_period");
      if (has_permission) return has_permission;
      else return false;
    },
    periodsFields() {
      if (!this.allows_crud)
        return this.periods_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.periods_fields;
    },
    periods_fields() {
      return [
        {
          key: "name",
          label: String(this.$getVisibleNames("mesh.period", true, "Períodos")),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "period_type",
          label: String(
            this.$getVisibleNames("mesh.periodtype", false, "Régimen")
          ),
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            if (!value) return "N/A";
            return (
              (this.regimes.find((x) => x.id == value) || {}).name || "N/A"
            );
          },
        },
        {
          key: "start_date",
          label: "Inicio",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            if (!value) return value;
            return this.$moment(value).format("DD/MM/YYYY");
          },
        },
        {
          key: "end_date",
          label: "Término",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            if (!value) return value;
            return this.$moment(value).format("DD/MM/YYYY");
          },
        },
        {
          key: "weeks",
          label: "Semanas",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(
          (this.regimes.find((x) => x.id == row.period_type) || {}).name || "",
          this.input_search
        ) ||
        this.$filtered(
          row.start_date
            ? this.$moment(row.start_date).format("DD/MM/YYYY HH:mm")
            : "",
          this.input_search
        ) ||
        this.$filtered(
          row.end_date
            ? this.$moment(row.end_date).format("DD/MM/YYYY HH:mm")
            : "",
          this.input_search
        ) ||
        this.$filtered(row.weeks, this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    askForDeletePeriod(period_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.period",
          false,
          "Período"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_PERIOD, period_id).then(() => {
            toast(
              String(
                this.$getVisibleNames("mesh.period", false, "Período") +
                  " eliminada."
              )
            );
          });
        }
      });
    },
    slotCreatedPeriod() {
      this.$bvModal.hide("new-period-modal");
    },
    slotUpdatedPeriod(period) {
      this.$bvModal.hide(`edit-period-modal-${period.id}`);
    },
  },
  created() {
    this.sortBy = "name";
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_REGIMES);
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-period {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>