var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.period", true, "Períodos"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.period',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_period'),expression:"'mesh.add_period'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
          'mesh.period',
          false,
          'Período'
        )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-period-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),(_vm.periods.length > 0)?_c('GenericBTable',{attrs:{"filterCustom":_vm.filterCustom,"items":_vm.periods,"pagination":_vm.periods.length,"fields":_vm.periodsFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_period'),expression:"'mesh.change_period'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
          'mesh.period',
          false,
          'Período'
        )}`,"click_button":() => _vm.$bvModal.show(`edit-period-modal-${row.item.id}`),"icon":'square'}}),_c('b-modal',{attrs:{"id":`edit-period-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames('mesh.period', false, 'Período')}`,"size":"md","hide-footer":""}},[_c('PeriodForm',{attrs:{"Period":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedPeriod}})],1)]}}:null],null,true)},[void 0],2):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ningún "+_vm._s(_vm.$getVisibleNames("mesh.period", false, "Período"))+".")])]),_c('b-modal',{attrs:{"id":`new-period-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('mesh.period', false, 'Período')}`,"size":"lg"}},[_c('PeriodForm',{on:{"created":_vm.slotCreatedPeriod}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }